swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: left;
  background: transparent;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}
.headerInsurance {
  font-size: 20px;
  color: #0D0C0C;
}

.titleInsurance {
  font-size: 20px;
  color: #0D0C0C;
  font-weight: 500 !important;
}

.FormLabelInsurance {
  color: #0D0C0C;
  font-size: 16px;
  background-color: #FDFDFC !important;
  font-weight: 400 !important;
}

.InputInsurance, .SelectInsurance {
  border-color: #E1DFDA !important;
  border-radius: 4px !important;
  padding-left: 16px !important;
}

.TotalInsurance {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #0D0C0C !important;
}

.quoteButton {
  width: 75% !important;
  color: #0D0C0C !important;
  font-weight: 500 !important;
  border-radius: 100px !important;
  height: 56px !important;
  -webkit-tap-highlight-color: transparent !important;
}

.quoteButton:active {
  opacity: 0.5 !important;
}

.ActionSheetButton {
  -webkit-tap-highlight-color: transparent !important;
}

.ActionSheetButton:active {
  opacity: 0.5 !important;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
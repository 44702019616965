.swiper {
  width: 100%;
  height: 87vh;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}

.swiper-button-next,
.swiper-button-prev {
  color: #ffd233 !important;
  fill: #ffd233 !important;
  stroke: #ffd233 !important;
}

.top-right {
  position: absolute;
  top: 20%;
  right: 3%;
}

.top-left {
  position: absolute;
  top: 15%;
  left: 3.5%;
}

.bottom-left {
  position: absolute;
  bottom: 15%;
  left: 3.5%;
}

.bottom-right {
  position: absolute;
  bottom: 10%;
  left: 3%;
}

.center-text {
  position: absolute;
  top: 20%;
  left: 10%;
}

.swiper-pagination-bullet-active {
  background-color: #f1e6aa !important;
}

.mySwiper {
  position: relative;
  height: 98%;
}
.hirePlan {
  flex: 1;
  min-height: 100vh;
  width: 100%;
  margin-top: -8px;
  padding: 16px;
}

.title {
  font-size: 20px;
  color: #0D0C0C;
  font-weight: 500;
  margin-top: 8px;
}

.text {
  font-weight: 400;
  color: #0D0C0C;
  margin-top: 4px;
}

.imagePressable {
  background-color: #F5F3EF;
  border: 2px dashed #D4D2CD;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 227px;
  border-radius: 24px;
  position: relative;
  cursor: pointer;
}

.imageText {
  color: #A8A6A0;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-top: 4px;
  line-height: 24px;
  white-space: pre-line;
}

.cardTitle {
  color: #0d0c0c;
  font-size: 15px;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 8px;
}

.secondaryText {
  color: #777672;
  font-size: 14px;
  margin-left: 16px;
}

.cardHire {
  border: 1px solid #18C1C6;
  border-radius: 16px;
  padding: 2px;
  margin: 16px;
}

.cardPrice {  
  background-color: #05329B;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 4px;
  border: none;
}

.textCardPrice {
  color: #FDFDFC;
  font-size: 16px;
  font-weight: 600;
}

.smallText {
  font-size: 12px;
  font-weight: 400;
}

.bigPrice {
  font-size: 28px;
}

.secondaryCard {
  background-color: #EBF0F0;
  margin-top: -8px !important;
}

.secondaryPrice {
  color: #28B6BD;
}

.smallSmall {
  font-size: 12px;
  margin-top: 16px;
}

.imagePreview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
}

.deleteButton {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #FDFDFC;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  cursor: pointer;
}

.uploadButton {
  width: 75% !important;
  background-color: #E3E829 !important;
  color: #0D0C0C !important;
  font-weight: 500 !important;
  border-radius: 100px !important;
  height: 56px !important;
  -webkit-tap-highlight-color: transparent !important;
}

.uploadButton:active {
  opacity: 0.5 !important;
}

.uploadButton:disabled {
  background-color: #1D1B201F !important;
  cursor: not-allowed;
}

/* Modal Styles */
.modalIcon {
  width: 80px;
  height: 80px;
  background: #F5F3EF;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 16px;
}

.modalTitle {
  font-size: 20px;
  font-weight: 700;
  color: #0D0C0C;
  margin-bottom: 8px;
}

.modalSubtitle {
  font-size: 16px;
  color: #0D0C0C;
  margin-bottom: 16px;
}

.modalQuestion {
  font-size: 16px;
  color: #0D0C0C;
  font-weight: 500;
}

.optionButton {
  width: 100% !important;
  height: 56px !important;
  border: 1px solid #D4D2CD !important;
  border-radius: 100px !important;
  background: transparent !important;
  color: #0D0C0C !important;
  font-weight: 400 !important;
}

.optionButton.selected {
  background: #E3E829 !important;
  border-color: #E3E829 !important;
}

.imageInput {
  display: none;
}

.buttonPayment {
  position: relative !important;
  width: 100% !important;
  height: 80px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-bottom: 1px solid #F5F3EF !important;
  background-color: transparent !important;
  padding-inline: 0 !important;
  border-radius: 0 !important;
  -webkit-tap-highlight-color: transparent !important;
}

.buttonPayment:active {
  opacity: 0.5 !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

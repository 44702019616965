.paymentUserData {
  flex: 1;
  min-height: 100vh;
  width: 100%;
  margin-top: -8px;
  padding: 16px;
  background-color: #FDFDFC !important;
}

.titlePU {
  font-size: 20px;
  color: #0D0C0C;
  font-weight: 500 !important;
  margin-top: 8px;
}

.subtitlePU {
  font-size: 18px;
  color: #0D0C0C;
  font-weight: 600;
  margin-top: 24px;
}

.FormLabelPayment {
  color: #0D0C0C;
  font-size: 16px;
  background-color: #FDFDFC !important;
  font-weight: 400 !important;
}

.InputPayment {
  border-color: #E1DFDA !important;
  border-radius: 4px !important;
  padding-left: 16px !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.planDescription {
  flex: 1;
  background-color: #2C6DF6;
  min-height: 100vh;
  width: 100%;
}

.planContent {
  background-color: #FDFDFC;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: -2px;
  border-top-right-radius: 300px;
  padding: 10px 16px 28px 16px;
  height: 100vh;
  position: relative;
}

.boldPD {
  font-weight: 700;
  color: #000000;
  font-size: 32px;
  margin-bottom: 16px;
  white-space: pre-line;
}

.cardPD {
  border: 1px solid #18C1C6;
  border-radius: 16px;
  margin-top: 16px;
  padding: 2px;
}

.cardBorderPD {
  border: 1px solid #E1DFDA;
  border-radius: 14px;
  padding: 16px;
}

.cardPricePD {
  background-color: #05329B;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 4px;
  border: none;
}

.textCardPricePD {
  color: #FDFDFC;
  font-size: 16px;
  font-weight: 600;
}

.secondaryPricePD {
  color: #28B6BD;
}

.secondaryCardPD {
  background-color: #EBF0F0;
  margin-top: 4px;
}

.secondaryTextPD {
  color: #777672;
  font-size: 14px;
}

.bigPricePD {
  font-size: 28px;
}

.smallTextPD {
  font-size: 12px;
  font-weight: 400;
}

.planPD {
  font-weight: 700;
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
}

.planSubTitlePD {
  font-weight: 400;
  font-size: 16px;
  color: #777672;
  margin: 8px 0;
}

.smallPD {
  font-size: 14px;
  margin-top: 24px;
  flex-grow: 1;
}

.smallSmallPD {
  font-size: 12px;
  margin-top: 16px;
}

.hireButtonPD {
  width: 75% !important;
  background-color: #E3E829 !important;
  color: #0D0C0C !important;
  font-weight: 500 !important;
  border-radius: 100px !important;
  height: 56px !important;
  -webkit-tap-highlight-color: transparent !important;
}

.hireButtonPD:active {
  opacity: 0.5 !important;
}
